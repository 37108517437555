<template>
  <!--begin::Post-->
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">
      <div class="card">
        <div class="card-body">
          <Form
            @submit="handleSubmit"
            :validation-schema="schema"
            class="form w-100"
            novalidate="novalidate"
            id="kt_sign_in_form"
          >
            <div class="row">
              <div class="col">
                <label for="">Jabatan Penandatangan:</label>
                <Field
                  as="select"
                  name="sign_position_id"
                  class="form-control bg-transparent"
                  v-model="formInput.sign_position_id"
                >
                  <option value="">Select value</option>
                  <option
                    v-for="(select, i) in signJabatanOptions"
                    :key="i"
                    :value="select.value"
                  >
                    {{ select.label }}
                  </option>
                </Field>
                <ErrorMessage
                  name="sign_position_id"
                  class="text-danger error-feedback"
                />
              </div>
              <!-- <div class="col">
                <label for="">Pangkat Penanda Tangan:</label>
                <Field
                  as="select"
                  name="sign_rank_id"
                  class="form-control bg-transparent"
                  v-model="formInput.sign_rank_id"
                >
                  <option value="">Select Pangkat</option>
                  <option
                    v-for="(select, i) in options"
                    :key="i"
                    :value="select.value"
                  >
                    {{ select.label }}
                  </option>
                </Field>
                <ErrorMessage
                  name="sign_rank_id"
                  class="text-danger error-feedback"
                />
              </div> -->
              <div class="col">
                <label for="">Jenis Kartu</label>
                <div class="checkbox-inline mt-3">
                  <label class="checkbox">
                    <input
                      type="checkbox"
                      name="card_type"
                      value="KTP"
                      v-model="formInput.card_type"
                    />
                    <span></span>
                    KTP
                  </label>
                  &nbsp;
                  <label class="checkbox">
                    <input
                      type="checkbox"
                      name="card_type"
                      v-model="formInput.card_type"
                      value="KTAK"
                    />
                    <span></span>
                    KTAK
                  </label>
                  &nbsp;
                  <label class="checkbox">
                    <input
                      type="checkbox"
                      name="card_type"
                      v-model="formInput.card_type"
                      value="KTA ASN"
                    />
                    <span></span>
                    KTA ASN
                  </label>
                </div>
                <ErrorMessage
                  name="card_type"
                  class="text-danger error-feedback"
                />
              </div>
            </div>

            <hr />

            <div class="row mt-5" v-if="canSelectSigners">
              <div class="col-12">
                <label for="">Pilih Pangkat yang ditandatangani</label>
                <Field
                  as="select"
                  name="rank_ids"
                  class="form-control bg-transparent"
                  v-model="formInput.rank_ids"
                  multiple
                >
                  <option value="">Select value</option>
                  <option
                    v-for="(select, i) in options"
                    :key="i"
                    :value="select.value"
                  >
                    {{ select.label }}
                  </option>
                </Field>
                <ErrorMessage
                  name="rank_ids"
                  class="text-danger error-feedback"
                />
              </div>
              <div class="col-12 mt-5">
                <!-- <label for="">Pilih Jabatan yang di tandatangani</label>
                &nbsp; &nbsp; &nbsp; &nbsp;
                <span class="mt-2">
                  <input
                    type="radio"
                    id="jabatan"
                    name="jabatan_type"
                    value="jabatan"
                    v-model="positionType"
                  />
                  &nbsp;
                  <label for="jabatan"> Jabatan </label>
                  &nbsp; &nbsp;
                  <input
                    type="radio"
                    id="organisasi"
                    name="jabatan_type"
                    value="organisasi"
                    v-model="positionType"
                  />
                  &nbsp;
                  <label for="organisasi"> Organisasi </label>
                </span> -->
                <div class="row">
                  <div class="col">
                    <label for="">Pilih Jabatan yang di tandatangani</label>
                    <Field
                      as="select"
                      name="position_ids"
                      class="form-control bg-transparent mt-3"
                      v-model="formInput.position_ids"
                      multiple
                    >
                      <option value="">Select value</option>
                      <option
                        v-for="(select, i) in signJabatanOptions"
                        :key="i"
                        :value="select.value"
                      >
                        {{ select.label }}
                      </option>
                    </Field>
                    <ErrorMessage
                      name="position_ids"
                      class="text-danger error-feedback"
                    />
                  </div>
                  <div class="col">
                    <label for="">Organisasi</label>
                    <Field
                      as="select"
                      name="position_ids_flag"
                      class="form-control bg-transparent mt-3"
                      v-model="formInput.position_ids_flag"
                      multiple
                    >
                      <option value="">Select value</option>
                      <option
                        v-for="(select, i) in jabatanOrganisasiOptions"
                        :key="i"
                        :value="select.value"
                      >
                        {{ select.label }}
                      </option>
                    </Field>
                    <ErrorMessage
                      name="position_ids_flag"
                      class="text-danger error-feedback"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-5" v-if="canSubmit">
              <div class="col"></div>
              <div class="col-auto">
                <button class="btn btn-primary">Submit</button>
              </div>
            </div>
          </Form>
        </div>
      </div>

      <hr />
      <!--begin::Card-->
      <div class="card">
        <!--begin::Card body-->
        <div class="card-body">
          <BaseTable
            :columns="columns"
            :defaultFilter="searchParams"
            :service="'esign-map'"
            ref="table"
            @actionButton="handleAction"
            @selectedRows="handleSelected"
          >
            <template #header>
              <div class="row">
                <div class="col">
                  <select
                    class="form form-control"
                    name=""
                    id=""
                    v-model="searchParams.rank_id"
                  >
                    <option value="">Pilih Pangkat</option>
                    <option
                      v-for="(select, i) in options"
                      :key="i"
                      :value="select.value"
                    >
                      {{ select.label }}
                    </option>
                  </select>
                </div>
                <div class="col">
                  <select
                    class="form form-control"
                    name=""
                    id=""
                    v-model="searchParams.sign_rank_id"
                  >
                    <option value="">Pilih TTE Pangkat</option>
                    <option
                      v-for="(select, i) in options"
                      :key="i"
                      :value="select.value"
                    >
                      {{ select.label }}
                    </option>
                  </select>
                </div>
                <div class="col-auto">
                  <button class="btn btn-primary" @click="$refs.table.reload()">
                    Filter
                  </button>
                </div>
              </div>
            </template>

            <template #action-button="{ data }">
              <button
                class="btn btn-danger btn-sm"
                @click="handleAction({ data: data.value, type: 'delete' })"
              >
                Delete
              </button>
            </template>
          </BaseTable>
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Post-->
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import Service from "@/services/base.service";
import BaseTable from "@/components/BaseTable.vue";

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    BaseTable,
  },
  data() {
    const schema = yup.object().shape({
      sign_rank_id: yup.string(),
      sign_position_id: yup.string().required("Harus dipilih!"),
      rank_ids: yup.array(),
      position_ids: yup.array(),
      position_ids_flag: yup.array(),
    });

    return {
      loading: false,
      columns: [
        // { field: "id", title: "ID", isUnique: true, type: "number" },
        { field: "card_type", title: "Jenis Kartu" },
        // { field: "rank_sign.name", title: "TTE PANGKAT" },
        { field: "position_sign.name", title: "TTE JABATAN" },
        { field: "rank.name", title: "PANGKAT" },
        { field: "position.name", title: "JABATAN" },
        { field: "status", title: "STATUS" },
        { field: "actions", title: "Actions" },
      ],
      schema,
      formInput: {
        sign_rank_id: "",
        sign_position_id: "",
        rank_ids: [],
        position_ids: [],
        position_ids_flag: [],
        card_type: [],
      },
      searchParams: {
        rank_id: "",
        sign_rank_id: "",
        position_id: "",
        sign_position_id: "",
      },
      options: [],
      jabatanOptions: [],
      signJabatanOptions: [],
      jabatanOrganisasiOptions: [],
      rawJabatanOptions: [],
      positionType: null,
    };
  },
  mounted() {
    this.fetchData();
    // this.getListData();
  },
  computed: {
    canSelectSigners() {
      return (
        (this.formInput.sign_rank_id || this.formInput.sign_position_id) &&
        this.formInput.card_type.length > 0
      );
    },
    canSubmit() {
      return true;
      // return (
      //   (this.formInput.sign_rank_id || this.formInput.sign_position_id) &&
      //   this.formInput.card_type.length &&
      //   (this.formInput.rank_ids || this.formInput.position_ids)
      // );
    },
  },
  watch: {
    positionType(value) {
      this.fetchPosition(value);
    },
  },
  methods: {
    fetchPosition(type) {
      this.formInput.position_ids = [];
      this.jabatanOptions = this.rawJabatanOptions.filter(
        (r) => r.type.toLowerCase() == type.toLowerCase()
      );
    },
    async fetchData() {
      const PangkatService = new Service("master/pangkat");
      const JabatanService = new Service("master/jabatan");

      const [pangkat, jabatan] = await Promise.all([
        PangkatService.getListData(),
        JabatanService.getListData(),
      ]);

      this.options = pangkat.data.map((r) => {
        return {
          label: r.name,
          value: r.id,
        };
      });

      this.rawJabatanOptions = jabatan.data.map((r) => {
        const data = {
          label: r.name,
          value: r.id,
          type: r.type,
        };
        if (r.type && r.type.toLowerCase() == "jabatan") {
          this.signJabatanOptions.push(data);
        }
        if (r.type && r.type.toLowerCase() == "organisasi") {
          this.jabatanOrganisasiOptions.push(data);
        }
        return data;
      });

      // setTimeout(() => {
      //   this.signJabatanOptions = this.rawJabatanOptions.filter(
      //     (r) => r.type.toLowerCase() == "jabatan"
      //   );

      //   this.jabatanOrganisasiOptions = this.rawJabatanOptions.filter(
      //     (r) => r.type.toLowerCase() == "organisasi"
      //   );
      // }, 500);
    },
    handleAction(value) {
      switch (value.type) {
        case "create":
          this.$router.push({ name: "esign-create" });
          break;
        case "view":
          this.$router.push({
            name: "esign-update",
            params: { id: value.data.id },
          });
          break;
        case "delete":
          this.deleteConfim(value.data.id);
          break;
        default:
          break;
      }
    },
    deleteConfim(id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deleteAction(id);
          }
        });
    },
    async deleteAction(id) {
      try {
        const BaseService = new Service("esign-map");
        await BaseService.deleteData(id);
        this.$refs.table.reload();
      } catch (error) {
        console.log(error);
      }
    },
    handleSelected(value) {
      console.log("selected", value);
    },
    async handleSubmit() {
      try {
        const BaseService = new Service("esign-map");
        await BaseService.createData(this.formInput);
        this.$refs.table.reload();
      } catch (error) {
        console.log(error);
        this.$swal.fire(error);
      }
    },
  },
};
</script>
